import { Link } from "gatsby"
import React, { useState } from "react"
import logo from "./../../images/logo.png"
import "./navbar.scss"

function Navbar({ Location }) {
  let currentPath = ""

  if (typeof window !== "undefined") {
    currentPath = window.location.pathname.split("/")[1]
      ? window.location.pathname.split("/")[1]
      : "home"
  }

  const [activeMenu, setActiveMenu] = useState(currentPath)

  const menus = [
    {
      menu: "Beranda",
      link: "/",
      path: "home",
    },
    {
      menu: "Tentang Kami",
      link: "/about",
      path: "about",
    },
    {
      menu: "Produk",
      link: "/products",
      path: "products",
    },
    {
      menu: "Fasilitas",
      link: "/facilities",
      path: "facilities",
    },
    {
      menu: "Akses",
      link: "/access",
      path: "access",
    },
    {
      menu: "Kontak",
      link: "/contact",
      path: "contact",
    },
  ]

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img className="logo" src={logo} alt="" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse `} id="navbarNav">
          <div className="navbar-nav ms-auto">
            {menus.map(menu => (
              <Link
                className={`nav-link ${
                  menu.path === activeMenu ? `active` : ``
                }`}
                to={menu.link}
                key={menu.menu}
              >
                {menu.menu}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
